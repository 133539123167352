import { useContext, useEffect, useRef, useState } from "react";
import {createUseStyles} from 'react-jss'
import { AppContext } from "contexts/AppContext";
import _debounce from 'lodash.debounce';
import { Grid2 as Grid } from "@mui/material";
import Headline3 from "components/elements/Headline3";
import Headline6 from "components/elements/Headline6";
import ArrowLeft from "components/icons/ArrowLeft";
import ArrowRight from "components/icons/ArrowRight";
import "./HorizontalScrollBlock.css"
import ButtonMedium4 from "components/elements/ButtonMedium4";

const jss = createUseStyles({
    HorizontalScrollBlock:{
        '& input[type=range]' : {
            '&::-webkit-slider-thumb':{
                width: (sliderControlWidth) => `${sliderControlWidth}%`
            },
            '&::-moz-range-thumb':{
                width: (sliderControlWidth) => `${sliderControlWidth}%`
            },
            '&::-ms-thumb':{
                width: (sliderControlWidth) => `${sliderControlWidth}%`
            },
        }
    }
})

const HorizontalScrollBlock = (props) => {
    const { styleGuide } = useContext(AppContext)
    const scrollArea = useRef(null) // container
    const [slideNumberAtLeftEnd, setSlideNumberAtLeftEnd] = useState(0)
    const [slideNumberAtRightEnd, setSlideNumberAtRightEnd] = useState(0)
    const [rangeSliderValue, setRangeSliderValue] = useState(0)
    const [blocksRef, setBlocksRef] = useState([])
    const [sliderControlWidth, setSliderControlWidth] = useState(0)
    const [pushSliderToRightEnd, setPushSliderToRightEnd] = useState(false)
    const [pushSliderToLeftEnd, setPushSliderToLeftEnd] = useState(true)

    const boxes = props.children || []
    const blockIds = boxes?.map((item,index) => 'scrollBlock'+index);
    const classes = jss(sliderControlWidth)
    let isDown = false;
    let startX;
    let scrollLeft;

    const caption = props.caption || ''
    const customClassNames = props.className || ''
    const customStyle = props.style

    const scrollToBlock = (blockNumber) => {
        const block = document.getElementById('scrollBlock' + blockNumber)
        if(block) scrollArea.current.scrollLeft = block.offsetLeft - 2
    }
    const nextBlock = (disabled = false) => {
        if (pushSliderToRightEnd) return null
        const nextSlide = slideNumberAtLeftEnd + 1
        scrollToBlock(nextSlide)
    }
    const prevBlock = (disabled = false) => {
        if (pushSliderToLeftEnd) return null
        const prevSlide = (slideNumberAtLeftEnd <= 0) ? 0 : slideNumberAtLeftEnd - 1
        scrollToBlock(prevSlide)
    }

    const changeRangeBar = (e) => {
        const rangeValue = e.target.value || 0
        scrollArea.current.scrollLeft = scrollArea.current.scrollWidth * (rangeValue/100)
    }

    const findNearestBlockNumber = (currentPosition, blockOffsets, direction = 'left') => {
        if(!blockOffsets || !blockOffsets.length) return null
        const offSetMarginAdjustment = (direction === 'right') ? 0 : 40
        const index = blockOffsets.slice().reverse().findIndex(offset => currentPosition >= offset - offSetMarginAdjustment)
        const count = blockOffsets.length - 1
        let finalIndex = index >= 0 ? count - index : index;
        finalIndex = finalIndex < 0 ? 0 : finalIndex
        return finalIndex;
    }

    const scrollContainer = (scrollContainer) => {
        if(!scrollContainer || isNaN(scrollContainer.scrollLeft) || isNaN(scrollContainer.scrollWidth) || isNaN(scrollContainer.offsetWidth)) return null

        const blockOffsets = blocksRef.length ? blocksRef.map(item => item.offsetLeft) : initialiseBlockRefs()?.map(item => item.offsetLeft)
        const slideAtLeftEnd = findNearestBlockNumber(scrollContainer.scrollLeft, blockOffsets)
        const slideAtRightEnd = findNearestBlockNumber(scrollContainer.scrollLeft + scrollContainer.offsetWidth, blockOffsets, 'right')
        const reachedRightEnd = scrollArea.current.scrollWidth - scrollArea.current.scrollLeft <= scrollArea.current.offsetWidth
        const reachedLeftEnd = (0 === scrollArea.current.scrollLeft)

        let sliderValue =  reachedLeftEnd ? 0 : reachedRightEnd ? 100 : 100 * scrollContainer.scrollLeft / (scrollContainer.scrollWidth - scrollContainer.offsetWidth)

        setPushSliderToLeftEnd(reachedLeftEnd)
        setPushSliderToRightEnd(reachedRightEnd)
        setRangeSliderValue(sliderValue)
        setSlideNumberAtLeftEnd(slideAtLeftEnd)
        setSlideNumberAtRightEnd(slideAtRightEnd)
    }

    // form the blocks based on children passed
    const initialiseBlockRefs = () => {
        if(!blockIds || !blockIds.length) return null
        let blockArray = []
        blockIds.forEach((item) => {
            const element = document.getElementById(item)
            if(element) blockArray.push(element)
        })
        setBlocksRef(blockArray)
        return blockArray
    }

    useEffect(() => {
        // to click and drag or swipe the blocks
        if (scrollArea.current !== null) {
            scrollArea.current.addEventListener('mousedown', (e) => {
                isDown = true;
                scrollArea.current?.classList.add('active');
                startX = e.pageX - scrollArea.current?.offsetLeft;
                scrollLeft = scrollArea.current?.scrollLeft;
            });
            scrollArea.current.addEventListener('mouseleave', () => {
                isDown = false;
            });
            scrollArea.current.addEventListener('mouseup', () => {
                isDown = false;
            });
            scrollArea.current.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - scrollArea.current.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                scrollArea.current.scrollLeft = scrollLeft - walk;
            });
        }

        // window resize handler
        window.addEventListener('resize', _debounce(() => scrollContainer(scrollArea.current), 200));
        return () => {
            window.removeEventListener('resize', _debounce(() => scrollContainer(scrollArea.current), 200));

        }
    }, [])

    useEffect(() => {
        initialiseBlockRefs()
        const frameWidth = scrollArea.current?.offsetWidth
        const totalScrollWidth = scrollArea.current?.scrollWidth
        const sliderWidthRatio = frameWidth / totalScrollWidth
        const sliderWidthForCSS = sliderWidthRatio * 100
        setSliderControlWidth(sliderWidthForCSS)
        scrollContainer(scrollArea.current)
    },[boxes])


    if (!boxes || !boxes.length) return null

    return (
        <div className={classes.HorizontalScrollBlock + ' horizontalScrollBlock ' + customClassNames} style={customStyle}>
            <div className="grid-container">
                {
                    !!caption &&
                    <Headline3>{caption}</Headline3>
                }
                {props.buttonLeft || props.button ? <div></div> : null}
                {props.buttonRight || props.button || null}
                <main className="grid-item main hide-scroll">
                    <div className="items hide-scroll" ref={scrollArea} onScroll={(e) => scrollContainer(e.target)} style={{ scrollBehavior: 'smooth' }}>
                        {boxes && boxes.length
                            ?
                            boxes.map((item, index) => (
                                <div key={index} className="item" id={'scrollBlock' + index}>
                                    {item}
                                </div>
                            ))
                            :
                            null
                        }
                    </div>
                </main>
            </div>
            <Grid container>
                <Grid size={{ xs: 6, md: 1 }}
                    sx={{
                        '.right-slide-number': {
                            display: ['none', 'inline-block', 'inline-block', 'inline-block', 'inline-block', 'inline-block']
                        }
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <Headline6 className="left-slide-number"> {(slideNumberAtLeftEnd + 1).toLocaleString('en', { minimumIntegerDigits: 2 })} </Headline6>
                        {boxes.length > 1 && <Headline6 className="right-slide-number"> - {(slideNumberAtRightEnd + 1).toLocaleString('en', { minimumIntegerDigits: 2 })} </Headline6>}
                        <Headline6 style={{ color: styleGuide.color5 }}> / {boxes && boxes.length ? boxes.length.toLocaleString('en', { minimumIntegerDigits: 2 }) : null} </Headline6>
                    </div>
                </Grid>
                <Grid size={{ xs: 0, md: 9 }} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <div style={{ textAlign: 'center' }}>
                        <form className="rangeSlider">
                            <input
                                id="scroll-range"
                                className="scroll-range"
                                type="range"
                                min={0}
                                step={1}
                                max={100}
                                value={rangeSliderValue}
                                onInput={(e) => changeRangeBar(e)}
                                // onChange={(e) => changeRangeBar(e)}
                                style={{ width: '80%', backgroundColor: 'transparent' }}
                            />
                        </form>
                    </div>
                </Grid>
                <Grid size={{ xs: 6, md: 2 }}>
                    <div style={{ textAlign: 'center' }}>
                        <span onClick={() => prevBlock()} style={{ marginRight: '1rem', cursor: !(pushSliderToLeftEnd) ? 'pointer' : 'not-allowed' }}>
                            <ArrowLeft fill={pushSliderToLeftEnd ? styleGuide.color5 : styleGuide.color7} />
                        </span>
                        <span onClick={() => nextBlock()} style={{ marginLeft: '1rem', cursor: !(pushSliderToRightEnd) ? 'pointer' : 'not-allowed' }}>
                            <ArrowRight fill={pushSliderToRightEnd ? styleGuide.color5 : styleGuide.color7} />
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default HorizontalScrollBlock