import ButtonLarge1 from "components/elements/ButtonLarge1";
import ButtonMedium1 from "components/elements/ButtonMedium1";
import ButtonMedium4 from "components/elements/ButtonMedium4";
import CaptionText from "components/elements/CaptionText";
import ContentSmall from "components/elements/ContentSmall";
import CustomCheckbox from "components/elements/CustomCheckbox";
import ExplainerText from "components/elements/ExplainerText";
import Headline1 from "components/elements/Headline1";
import Headline4 from "components/elements/Headline4";
import Headline6 from "components/elements/Headline6";
import Image from "components/elements/Image";
import Close from "components/icons/Close";
import { AppContext } from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { callAPI } from "utils/API";
import {
  addProductToBasket,
  removeProductFromBasket,
  updateProductQuantityInBasket,
} from "utils/basket";
import { callTagManagerEvent, setupBasketData } from "utils/setup";
import Translate, { translate } from "utils/Translate";
import HorizontalScrollBlock from "components/blocks/horizontalScrollBlock/HorizontalScrollBlock";
import ProductNormal from "components/elements/ProductNormal";
import { Modal } from "react-bootstrap";
import Tick from "components/icons/Tick";
import ContentNormal from "components/elements/ContentNormal";
import CustomSelect from "components/elements/CustomSelect";
import { getTranslatedProductTitle, pointsConvertor } from "utils/general";
import { Grid2 as Grid } from "@mui/material";
import FreightInfo from "./FreightInfo";
import QuantityInput from "components/elements/QuantityInput";
import { setupProductImages } from "utils/productImageHelper";

const jss = createUseStyles({
  basketContainer: { display: "flex", flexDirection: "column", flex: 1 },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 600,
    marginTop: 10,
  },
  imageContainer: { height: 100, width: 80 },
  overviewContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 600,
    marginTop: 10,
    gap: 40,
  },
  overview: {
    padding: 30,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 410,
    gap: 10,
  },
  basketOverviewDelivery: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    marginTop: 30,
    justifyContent: "space-between",
  },
  negativeMarginTop: {},
  "@media (min-width: 1024px)": {
    basketContainer: { flexDirection: "row", justifyContent: "space-between" },
    itemsContainer: { flex: 0.5 },
    imageContainer: { height: 170, width: 140 },
    overviewContainer: { alignItems: "flex-end" },
    basketOverviewDelivery: { display: "flex", flexDirection: "column" },
    negativeMarginTop: { marginTop: "-44px" },
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    basketOverviewDelivery: {
      flexDirection: "row",
      maxWidth: "100%",
      gap: "auto",
    },
    overview: { maxWidth: "100%" },
    itemsContainer: { maxWidth: "100%" },
    overviewContainer: { maxWidth: "100%" },
  },
});

const Basket = (props) => {
  const {
    basket,
    setBasket,
    styleGuide,
    accessibleShops,
    userData,
    brandData,
  } = useContext(AppContext);
  const classes = jss();
  const ShopID =
    accessibleShops && accessibleShops.length > 0
      ? accessibleShops[0].ShopID
      : null;
  const navigate = useNavigate();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderResult, setOrderResult] = useState(null);
  const [stockError, setStockError] = useState(null);
  const [unavailable, setUnavailable] = useState(null);
  const [tagCount, setTagCount] = useState(0);
  const [freightInfo, setFreightInfo] = useState(null);
  const [minOrderViolatedProducts, setMinOrderViolatedProducts] = useState([]);
  const [updateDeliveryInfo, setUpdateDeliveryInfo] = useState(false);

  const totalPrice = basket.length
    ? basket.reduce((sum, item) => {
        const productDetails = item.product[0];
        if (!productDetails) return (sum += 0);
        const price =
          (productDetails.discountPointComputed
            ? productDetails.discountPointComputed
            : productDetails.productPointComputed) * item.amount;
        sum += price;
        return sum;
      }, 0)
    : 0;

  const dropshipItems =
    basket &&
    basket.length &&
    basket.filter((b) => b.product.length && b.product[0].enableDropship);
  const splitItemsByFreight =
    brandData.features &&
    brandData.features.enableSplitItemsByFreight &&
    dropshipItems.length &&
    dropshipItems.length !== basket.length &&
    (!brandData.freeFreightOrderLimit ||
      totalPrice < brandData.freeFreightOrderLimit);

  let freightPoints = 0;
  let dropshipPoints = 0;
  if (splitItemsByFreight) {
    if (
      freightInfo?.enableFreightPriceInterval &&
      freightInfo.freightIntervals?.length
    ) {
      const totalProductWeight = basket
        .filter((item) => !item.product?.[0].enableDropship)
        .reduce(
          (sum, item) => sum + item.product?.[0]?.productWeightInGram || 0,
          0
        );
      const interval = freightInfo.freightIntervals.find(
        (freight) =>
          freight.orderWeightFrom <= totalProductWeight &&
          totalProductWeight <= freight.orderWeightTo
      );
      freightPoints = interval?.freightPoints || 0;
      if (freightInfo.enableDropshipFreightPrice) {
        dropshipPoints = basket
          .filter((item) => !!item.product?.[0]?.enableDropship)
          .reduce(
            (sum, item) => sum + item.product?.[0]?.dropshipFreightPoints || 0,
            0
          );
      }
    }
  }

  const mapStockErrorToBasketItem = (basketFromContext) => {
    if (!basketFromContext || !basketFromContext.BasketID) return null;
    if (
      !!stockError &&
      stockError.basket &&
      stockError.basket.BasketID === basketFromContext.BasketID
    )
      return stockError;
    return null;
  };

  const fetchFreightIntervals = async () => {
    const response = await callAPI("/brand/freightIntervals");
    if (response && Object.keys(response).length) {
      setFreightInfo({
        enableDropshipFreightPrice: response.enableDropshipFreightPrice,
        enableFreightPriceInterval: response.enableFreightPriceInterval,
        freightIntervals: response.freightIntervals,
      });
    }
  };

  useEffect(() => {
    const productIdToBasketAmount = basket.reduce((map, basketEntry) => {
      map[basketEntry.ProductID] =
        (map[basketEntry.ProductID] || 0) + basketEntry.amount;
      return map;
    }, {});

    const productsBelowMinCount = basket.filter(
      (b) => productIdToBasketAmount[b.ProductID] < b.product[0]?.minCount
    );
    const productsBelowMinCountUnique = [
      ...new Map(
        productsBelowMinCount.map((item) => [item.ProductID, item.product[0]])
      ).values(),
    ];
    setMinOrderViolatedProducts(productsBelowMinCountUnique);
  }, [basket]);

  useEffect(() => {
    fetchFreightIntervals();
    return () => setupBasketData({ userData, setBasket });
  }, []);

  const basketTotalAmount = basket
    ? basket.reduce(
        (totalAmount, basketEntry) => basketEntry.amount + totalAmount,
        0
      )
    : 0;

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 100 }}>
        {!orderPlaced ? (
          <Headline1
            translationKey="_BASKET_CAPTION_"
            fallbackText="Din kurv"
            style={{ color: styleGuide.color7, lineHeight: "74px" }}
          />
        ) : (
          <Headline1
            translationKey="_BASKET_CAPTION_ORDER_CONFIRMATION"
            fallbackText="Ordrebekræftelse"
            style={{ color: styleGuide.color7, lineHeight: "74px" }}
          />
        )}

        <div className={classes.basketContainer}>
          <div className={classes.itemsContainer}>
            {!orderPlaced ? (
              <Headline6
                style={{
                  color: styleGuide.color6,
                  lineHeight: "28px",
                  marginLeft: 3,
                }}
              >
                {translate(
                  "_BASKET_QUANTITY_CAPTION_",
                  `Du har ## ting i kurven`
                ).replace("##", basketTotalAmount)}
              </Headline6>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ContentNormal translationKey="_BASKET_ORDER_CONFIRMATION_MESSAGE_LINE_1_">
                  Tak for din ordre. Lagervarer afsendes indenfor 3-4 hverdage.
                  Du modtager en SMS og/eller email, når din ordre sendes fra
                  lageret.
                </ContentNormal>
                <ContentNormal
                  translationKey="_BASKET_ORDER_CONFIRMATION_MESSAGE_LINE_2_"
                  style={{ marginTop: "2rem" }}
                >
                  Du kan følge din ordrestatus under din profil.
                </ContentNormal>
                <ButtonMedium1
                  showIcon={false}
                  fallbackText={translate(
                    "_GO_TO_PROFILE_BUTTON_TEXT_",
                    "Gå til din profil"
                  )}
                  style={{
                    marginTop: "1rem",
                    maxWidth: 140,
                    alignItems: "stretch",
                  }}
                  onClick={() => navigate("/profile")}
                />
              </div>
            )}
            {!!splitItemsByFreight ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: styleGuide.color2,
                  }}
                >
                  {basket
                    .filter((b) => !b.product[0].enableDropship)
                    .map((item, idx) => (
                      <BasketItemCard
                        stockError={mapStockErrorToBasketItem(item)}
                        minOrderError={minOrderViolatedProducts.some(
                          (p) => p.ProductID === item.ProductID
                        )}
                        readOnly={orderPlaced}
                        basket={item}
                        baskets={basket}
                        key={idx}
                        showDivider={idx < basket.length - 1}
                        setUnavailable={setUnavailable}
                      />
                    ))}
                  <div
                    style={{
                      gap: 16,
                      display: "flex",
                      alignSelf: "flex-end",
                      marginTop: 8,
                      marginBottom: 8,
                      paddingRight: 8,
                      justifyContent: "space-between",
                    }}
                  >
                    <ContentSmall
                      translationKey="_BASKET_ITEM_FREIGHT_LABEL_"
                      fallbackText="Levering"
                    />
                    <ContentSmall
                      style={{
                        alignSelf: "flex-end",
                        color: styleGuide.color16,
                      }}
                      fallbackText={pointsConvertor(
                        brandData,
                        freightPoints,
                        translate("_POINTS_SHORT_", "pt")
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: styleGuide.color2,
                  }}
                >
                  {basket
                    .filter((b) => !!b.product[0].enableDropship)
                    .map((item, idx) => (
                      <BasketItemCard
                        stockError={mapStockErrorToBasketItem(item)}
                        minOrderError={minOrderViolatedProducts.some(
                          (p) => p.ProductID === item.ProductID
                        )}
                        readOnly={orderPlaced}
                        basket={item}
                        baskets={basket}
                        key={idx}
                        showDivider={idx < basket.length - 1}
                        setUnavailable={setUnavailable}
                      />
                    ))}
                  <div
                    style={{
                      gap: 16,
                      display: "flex",
                      alignSelf: "flex-end",
                      marginTop: 8,
                      marginBottom: 8,
                      paddingRight: 8,
                      justifyContent: "space-between",
                    }}
                  >
                    <ContentSmall
                      translationKey="_BASKET_ITEM_DROPSHIP_LABEL_"
                      fallbackText="Dropship"
                    />
                    <ContentSmall
                      style={{
                        alignSelf: "flex-end",
                        color: styleGuide.color16,
                      }}
                      fallbackText={pointsConvertor(
                        brandData,
                        dropshipPoints,
                        translate("_POINTS_SHORT_", "pt")
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : (
              basket &&
              basket.map((item, idx) => (
                <BasketItemCard
                  stockError={mapStockErrorToBasketItem(item)}
                  minOrderError={minOrderViolatedProducts.some(
                    (p) => p.ProductID === item.ProductID
                  )}
                  readOnly={orderPlaced}
                  basket={item}
                  baskets={basket}
                  key={idx}
                  showDivider={idx < basket.length - 1}
                  setUnavailable={setUnavailable}
                />
              ))
            )}
            <div>
              {unavailable && (
                <ContentNormal translationKey="_UNAVAILABLE_PRODUCT_ERROR_MESSAGE">
                  Du har et udgået produkt i kurven
                </ContentNormal>
              )}
            </div>
          </div>
          <div
            className={`${classes.overviewContainer} ${
              !!orderResult ? classes.negativeMarginTop || "" : ""
            }`}
          >
            <BasketOverview
              tagCount={tagCount}
              setTagCount={setTagCount}
              unavailable={unavailable}
              setUnavailable={setUnavailable}
              readOnly={orderPlaced}
              orderResult={orderResult}
              setStockError={(obj) => setStockError(obj)}
              setOrderPlaced={(val) => {
                setOrderPlaced(!!val);
                setOrderResult(val);
              }}
              freightInfo={freightInfo}
              minOrderViolatedProducts={minOrderViolatedProducts}
            />
          </div>
        </div>
        {!orderPlaced && (
          <ProductsBasedOnWalletBalance
            style={{
              position: "relative",
              marginTop: 100,
              marginBottom: -100,
              paddingTop: 100,
              paddingBottom: 100,
              backgroundColor: styleGuide.color2,
            }}
            button={
              <ButtonMedium4
                style={{ marginRight: "2rem", justifySelf: "end" }}
                showIcon={false}
                translationKey="_HOME_POPULAR_PRODUCTS_SEE_MORE_BUTTON_"
                onClick={() =>
                  navigate(`/shop/${ShopID}`, { state: { sort: 5 } })
                } // 5 : sort value for '_SHOP_SORT_WITHIN_MY_POINTS_LABEL_'
              >
                Se alle produkter
              </ButtonMedium4>
            }
          />
        )}
      </div>
    </>
  );
};

export default Basket;

export const BasketOverview = (props) => {
  const {
    styleGuide,
    basket,
    userData,
    setUserData,
    setStatusscroll,
    brandData,
    setBasket,
    langCode,
  } = useContext(AppContext);
  const {
    readOnly,
    setOrderPlaced,
    orderResult,
    setStockError,
    unavailable,
    setUnavailable,
    freightInfo,
    minOrderViolatedProducts,
  } = props;
  const { enableOnePointUsers } = brandData?.features || null;
  const classes = jss();
  const [readTermsAndConditions, setreadTermsAndConditions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [totalPrice, settotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedName, setUpdatedName] = useState(
    userData.userName || ""
  );
  const [updatedAddress, setUpdatedAddress] = useState(
    userData.userAddress || ""
  );
  const [updatedZip, setUpdatedZip] = useState(userData.userZipcode || "");
  const [updatedCity, setUpdatedCity] = useState(userData.userCity || "");
  const [updatedPhone, setUpdatedPhone] = useState(userData.userPhone || "");
  const [isAddressCustom, setIsAddressCustom] = useState(false)
  const [updateDeliveryInfo, setUpdateDeliveryInfo] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);
  const [addresses, setAddresses] = useState([
    {
      Dealer_Navn: "",
      Dealer_adresse: userData.userAddress,
      Dealer_zip: userData.userZipcode,
      Dealer_by: userData.userCity,
    },
  ]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const navigate = useNavigate();
  const skipWallet = !!brandData?.features?.skipWalletForOrders;
  let freightPoints = 0;
  let dropshipPoints = 0;
  if (
    (!brandData.freeFreightOrderLimit ||
      totalPrice < brandData.freeFreightOrderLimit) &&
    basket.length &&
    freightInfo?.enableFreightPriceInterval &&
    freightInfo.freightIntervals?.length
  ) {
    const totalProductWeight = basket
      .filter(
        (item) =>
          !item.product[0]?.enableDropship ||
          !item.product[0]?.dropshipFreightPoints
      )
      .reduce(
        (sum, item) =>
          sum + item.product[0]?.productWeightInGram * item.amount || 0,
        0
      );
    if (totalProductWeight > 0) {
      const interval = freightInfo.freightIntervals.find(
        (freight) =>
          freight.orderWeightFrom <= totalProductWeight &&
          totalProductWeight <= freight.orderWeightTo
      );
      freightPoints = interval?.freightPoints || 0;
    }
    dropshipPoints = basket
      .filter((item) => !!item.product[0]?.enableDropship)
      .reduce(
        (sum, item) => sum + item.product[0]?.dropshipFreightPoints || 0,
        0
      );
  }
  const totalTax =
    userData.taxPercentage && totalPrice > 0
      ? Math.ceil(
          (((totalPrice + freightPoints + dropshipPoints) /
            (brandData.brandPointRatio || 1)) *
            userData.taxPercentage) /
            100
        )
      : null;

  let missingParams = [];
  !userData.userZipcode && missingParams.push(translate("_ZIPCODE_", "Postnr"));
  !userData.userCompany &&
    brandData.BrandID !== 121 &&
    missingParams.push(translate("_COMPANY_", "Firma"));
  !userData.userAddress &&
    missingParams.push(translate("_ADDRESS_", "Adresse"));
  if (brandData.BrandID === 87) {
    !userData.userPhone && 
    missingParams.push(translate("_PHONE_", "Telefonnummer"))
  }
  !userData.userCity && missingParams.push(translate("_CITY_", "By"));
  const userAddressErrorText = `${missingParams.join(", ")} ${translate(
    "_ORDER_OVERVIEW_MISSING_PROFILE_INFO_ERROR_",
    "information mangler i din profil. Disse felter skal udfyldes før din ordre kan gennemføres"
  )}`;

  const calculateTotalPrice = () => {
    let cost = 0;
    basket.forEach((item) => {
      const productDetails = item.product[0];
      if (!productDetails) return;
      const price =
        (productDetails.discountPointComputed
          ? productDetails.discountPointComputed
          : productDetails.productPointComputed) * item.amount;
      cost += price;
    });
    settotalPrice(cost);
    if (props.tagCount === 0) {
      props.setTagCount(1);
      callTagManagerEvent({
        dataLayer: {
          event: "view_cart",
          ecommerce: {
            currency: "DKK",
            value: cost,
            items: basket.map((item, index) => {
              const productDetails =
                item.product && item.product.length ? item.product[0] : {};
              const allVariations = productDetails.variations || [];
              const selectedVariation = allVariations.filter(
                (v) => v.VariationID === item.VariationID
              )[0];

              const price =
                (productDetails.discountPointComputed
                  ? productDetails.discountPointComputed
                  : productDetails.productPointComputed) * item.amount;
              return {
                item_id: productDetails.ProductID,
                item_name: productDetails.elementTitle,
                discount: productDetails.discountPointComputed
                  ? productDetails.productPointComputed -
                    productDetails.discountPointComputed
                  : 0,
                index: index,
                item_brand: productDetails.manufacturer,
                item_category: productDetails.categories?.length
                  ? productDetails.categories.map((c) => c.categoryName).join()
                  : "",
                item_variant: selectedVariation?.variationTitle,
                price: price,
                quantity: item.amount,
              };
            }),
          },
        },
      });
    }
  };

  const placeOrder = async () => {
    setIsLoading(true);
    let request = { body: { baskets: basket } };
    if (addresses.length > 1 && !!selectedAddress) {
      let altAddress = addresses.find(
        (address) =>
          selectedAddress ===
          `${address.Dealer_Navn} ${address.Dealer_adresse}, ${address.Dealer_zip} ${address.Dealer_by}`
      );
      request.body.altAddress = altAddress;
    }
    if (userData.BrandID === 87) {
      request.body.msalData = {
        userCompany: userData.userCompany,
        userCompanyUID: userData.userCompanyUID,
        selectedCustomerNumber: userData.selectedCustomerNumber,
        userAddress: userData.userAddress,
        userZipcode: userData.userZipcode,
        userCity: userData.userCity,
      };
    }

    if (isAddressCustom) {
        request.body.customDeliveryAddress = {
            userName: updatedName ? updatedName : userData.userName,
            userAddress: updatedAddress ? updatedAddress : userData.userAddress,
            userZipcode: updatedZip ? updatedZip :userData.userZipcode,
            userCity: updatedCity ? updatedCity : userData.userCity,
            userPhone: updatedPhone ? updatedPhone : userData.userPhone,
        }
    }
    const orderResponse = await callAPI("order/submit", "POST", request);
    if (
      !orderResponse ||
      !orderResponse.ConfirmationID ||
      isNaN(orderResponse.ConfirmationID) ||
      orderResponse.ConfirmationID <= 0
    ) {
      setIsLoading(false);
      return parseError(orderResponse);
    }

    setOrderPlaced(orderResponse);
    if (skipWallet) {
      setIsLoading(false);
      return;
    }

    setUserData({
      ...userData,
      walletAmountPoints:
        userData.walletAmountPoints -
        totalPrice -
        freightPoints -
        dropshipPoints,
    });
    const itemsForTracking = basket.map((item, index) => {
      const productDetails =
        item.product && item.product.length ? item.product[0] : {};
      const allVariations = productDetails.variations || [];
      const selectedVariation = allVariations.filter(
        (v) => v.VariationID === item.VariationID
      )[0];

      const price =
        (productDetails.discountPointComputed
          ? productDetails.discountPointComputed
          : productDetails.productPointComputed) * item.amount;
      return {
        item_id: productDetails.ProductID,
        item_name: productDetails.elementTitle,
        discount: productDetails.discountPointComputed
          ? productDetails.productPointComputed -
            productDetails.discountPointComputed
          : 0,
        index: index,
        item_brand: productDetails.manufacturer,
        item_category: productDetails.categories?.length
          ? productDetails.categories.map((c) => c.categoryName).join()
          : "",
        item_variant: selectedVariation?.variationTitle,
        price: price,
        quantity: item.amount,
      };
    });
    callTagManagerEvent({
      dataLayer: {
        event: "purchase",
        ecommerce: {
          transaction_id: orderResponse.ConfirmationID,
          value: totalPrice,
          tax: 0,
          shipping: freightPoints + dropshipPoints,
          currency: "DKK",
          items: itemsForTracking,
        },
      },
    });
    setIsLoading(false);
    // callKlaviyoClientAPI(userData, "Purchase", {ConfirmationID: orderResponse.ConfirmationID , items: itemsForTracking}, totalPrice+freightPoints+dropshipPoints)
  };

  const parseError = (errorResponse) => {
    if (!errorResponse || !errorResponse.error)
      return seterrorMessage(
        <Translate translationKey="INTERNAL_SERVER_ERROR">
          Something went wrong. Order failed.
        </Translate>
      );
    const errorKey =
      typeof errorResponse.error === "string"
        ? errorResponse.error
        : errorResponse.error.message
        ? errorResponse.error.message
        : "";
    switch (errorKey) {
      case "EMPTY_BASKET":
        return seterrorMessage(
          <Translate translationKey="EMPTY_BASKET">
            Your basket is empty.
          </Translate>
        );

      case "INVALID_ADDRESS":
        return seterrorMessage(
          <Translate translationKey="INVALID_ADDRESS">
            Please check delivery address.
          </Translate>
        );

      case "CANNOT_PROCESS_ORDERS_WITH_DIFFERENT_CATALOG_TYPE":
        return seterrorMessage(
          <Translate translationKey="CANNOT_PROCESS_ORDERS_WITH_DIFFERENT_CATALOG_TYPE">
            Cannot process orders with different catalog types.
          </Translate>
        );

      case "CANNOT_PROCESS_ORDERS_WITH_DIFFERENT_PRODUCT_BRANDID":
        return seterrorMessage(
          <Translate translationKey="CANNOT_PROCESS_ORDERS_WITH_DIFFERENT_PRODUCT_BRANDID">
            Cannot process orders with products of different Brands.
          </Translate>
        );

      case "BASKET_SELECTION_BEYOND_LIMIT":
        return seterrorMessage(
          <Translate translationKey="BASKET_SELECTION_BEYOND_LIMIT">
            Basket selection beyond limit.
          </Translate>
        );

      case "INSUFFICIENT_FUNDS_POINTS":
        return seterrorMessage(
          <Translate translationKey="INSUFFICIENT_FUNDS_POINTS">
            Insufficient points.
          </Translate>
        );

      case "SHOP_SELECTION_LIMIT_EXCEEDED":
        return seterrorMessage(
          <Translate translationKey="SHOP_SELECTION_LIMIT_EXCEEDED">
            Shop selection limit exceeded.
          </Translate>
        );

      case "VARIATION_UNAVAILABLE":
        return seterrorMessage(
          <Translate translationKey="VARIATION_UNAVAILABLE">
            Et eller flere produkter i din kurv er ikke tilgængelige.
          </Translate>
        );

      case "UNAVAILABLE_PRODUCT_ERROR":
        return seterrorMessage(
          <Translate translationKey="UNAVAILABLE_PRODUCT_ERROR">
            {errorResponse.error.data} er ikke tilgængelig. Hvis du har flere
            produkter i din kurv skal du fjerne dette produkt og gennemføre din
            ordre bagefter.
          </Translate>
        );

      case "VARIATION_NOT_IN_STOCK":
        setStockError(
          !!errorResponse.error.data ? errorResponse.error.data : null
        );
        return seterrorMessage(
          <Translate translationKey="VARIATION_NOT_IN_STOCK">
            Et eller flere produkter i din kurv er ikke på lager længere.
          </Translate>
        );

      case "MINIMUM_ORDER_QUANTITY_VIOLATED":
        return seterrorMessage(
          <Translate translationKey="MINIMUM_ORDER_QUANTITY_VIOLATED">
            The minimum order quantity is not met for one or more products.
          </Translate>
        );
      case "NO_PHONE_NUMBER_ERROR":
        return seterrorMessage(
          <Translate translationKey="NO_PHONE_NUMBER_ERROR">
            Indtast venligst et telefonnummer
          </Translate>
        );

      case "INTERNAL_SERVER_ERROR":
      case "Internal server error":
      default:
        return seterrorMessage(
          <Translate translationKey="INTERNAL_SERVER_ERROR">
            Something went wrong. Order failed.
          </Translate>
        );
    }
  };

  const onSubmitButton = () => {
    if (
      !skipWallet &&
      !brandData?.features?.disablePoints &&
      !readTermsAndConditions
    ) {
      return seterrorMessage(
        <Translate
          translationKey="_BASKET_ERROR_ACCEPT_TERMS_"
          fallbackText="Godkend venligst handelsbetingelser"
        />
      );
    }
    if (missingParams.length > 0) {
      return seterrorMessage(
        <Translate
          translationKey="_BASKET_ERROR_ADDRESS_"
          fallbackText="Tjek venligst fejlmeddelelsen relateret til adresse informationer"
        />
      );
    }
    if (addresses.length > 1 && !selectedAddress) {
      return seterrorMessage(
        <Translate
          translationKey="_BASKET_ERROR_ONLY_ONE_ADDRESS_"
          fallbackText="Vælg venligst kun én adresse"
        />
      );
    }
    if (
      !enableOnePointUsers &&
      !skipWallet &&
      !brandData?.features?.disablePoints &&
      userData.walletAmountPoints -
        (totalPrice + freightPoints + dropshipPoints) <
        0
    ) {
      return seterrorMessage(
        <Translate
          translationKey="_BASKET_PRICE_EXCEEDS_BALANCE_TEXT_"
          fallbackText="Du har ikke point nok til at gennemføre ordren"
        />
      );
    }
    placeOrder();
  };

  const getAddress = async () => {
    const addressesResponse = await callAPI("/user/addresses", "GET");
    setAddresses([...addresses, ...addressesResponse]);
  };

  const fetchBasket = async () => {
    await setupBasketData({ userData, setBasket });
  };

  const getProductTitle = (product) => {
    return product?.translations?.find((t) => t.languageKey === langCode)?.elementTitle ?? product.elementTitle
  };

  useEffect(() => {
    calculateTotalPrice();
    basket.forEach((basketItem) => {
      if (basketItem?.product[0]?.productStatus === "UNAVAILABLE") {
        setUnavailable(true);
      }
    });
  }, [basket, freightInfo]);

  useEffect(() => {
    if (!!errorMessage){
      setShowError(true);
    }else{
      setShowError(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    getAddress();
    fetchBasket();
  }, []);

  return (
    <>
      <Headline4
        translationKey="_BASKET_OVERVIEW_CAPTION_"
        fallbackText="Dit ordreoverblik"
        style={{ color: styleGuide.color7 }}
      />
      <div
        style={{ backgroundColor: styleGuide.color2 }}
        className={classes.overview}
      >
        {skipWallet ||
        brandData?.features?.disablePoints ||
        brandData?.BrandID == 87
          ? null
          : !readOnly && (
              <ContentSmall
                translationKey="_BASKET_OVERVIEW_CORRECT_INFORMATION_"
                fallbackText="Ret oplysninger"
                style={{
                  color: styleGuide.color16,
                  alignSelf: "flex-end",
                  cursor: "pointer",
                  marginBottom: 30,
                }}
                onClick={() => {
                  setStatusscroll(true);
                  navigate("/profile");
                }}
              />
            )}
        {!!orderResult && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ContentSmall
              translationKey="_BASKET_OVERVIEW_ORDERNUMBER_LABEL_"
              fallbackText="Ordrenummer"
            />
            <ContentSmall
              style={{ maxWidth: 154, alignSelf: "flex-end" }}
              fallbackText={orderResult.OrderID}
            />
          </div>
        )}
        {skipWallet ||
        brandData?.features?.disablePoints ||
        brandData?.features?.points === false ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Headline6
              translationKey="_BASKET_OVERVIEW_TOTAL_VALUE_LABEL_"
              fallbackText="Samlet ordreværdi"
            />
            <Headline6
              fallbackText={pointsConvertor(
                brandData,
                orderResult
                  ? orderResult.totalOrderPoints - orderResult.totalFreightPrice
                  : totalPrice,
                translate("_POINTS_SHORT_", "pt")
              )}
            />
          </div>
        )}
        {brandData?.features?.points === false || !totalTax ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ContentSmall
              translationKey="_BASKET_OVERVIEW_TOTAL_TAX_LABEL_"
              fallbackText="Beskatningspris"
            />
            <ContentSmall
              fallbackText={`${pointsConvertor(brandData, totalTax, "kr")}`}
            />
          </div>
        )}

        {!brandData?.features?.disablePoints &&
          !!freightInfo?.enableFreightPriceInterval && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ContentSmall
                translationKey="_BASKET_OVERVIEW_FREIGHT_LABEL_"
                fallbackText="Levering"
              />
              <ContentSmall
                fallbackText={
                  !brandData.freeFreightOrderLimit ||
                  totalPrice < brandData.freeFreightOrderLimit
                    ? pointsConvertor(
                        brandData,
                        orderResult?.totalFreightPrice ||
                          freightPoints + dropshipPoints,
                        translate("_POINTS_SHORT_", "pt")
                      )
                    : translate(
                        "_BASKET_OVERVIEW_FREE_FREIGHT_TEXT_",
                        "Fri levering"
                      )
                }
              />
            </div>
          )}

        {!orderResult &&
          (!!freightInfo?.enableDropshipFreightPrice ||
            !!freightInfo?.enableFreightPriceInterval) &&
          totalPrice < brandData.freeFreightOrderLimit && (
            <ContentSmall
              fallbackText={translate(
                "_BASKET_FREIGHT_LIMIT_TEXT_",
                `Du mangler at bestille for ${
                  brandData.freeFreightOrderLimit - totalPrice
                } point mere, for at opnå fri levering`
              )}
            />
          )}

        {!brandData?.features?.disablePoints &&
          !!freightInfo?.enableFreightPriceInterval && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Headline6
                translationKey="_BASKET_OVERVIEW_FREIGHT_TOTAL_LABEL_"
                fallbackText="Total inkl. levering"
              />
              <Headline6
                fallbackText={pointsConvertor(
                  brandData,
                  orderResult
                    ? orderResult.totalOrderPoints
                    : totalPrice + freightPoints + dropshipPoints,
                  translate("_POINTS_SHORT_", "pt")
                )}
              />
            </div>
          )}

        {skipWallet ||
        brandData?.features?.disablePoints ||
        brandData?.features?.points === false ||
        !!brandData?.features?.hideUserPointBalance ? null : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ContentSmall
              translationKey="_BASKET_OVERVIEW_POINT_BACK_LABEL_"
              fallbackText="Point tilbage"
            />
            <ContentSmall
              style={{ maxWidth: 154, alignSelf: "flex-end" }}
              fallbackText={
                enableOnePointUsers
                  ? userData?.walletAmountPoints < 0
                    ? translate(
                        "_BASKET_PRICE_EXCEEDS_BALANCE_TEXT_",
                        "Du har ikke point nok til at gennemføre ordren"
                      )
                    : `${pointsConvertor(
                        brandData,
                        userData.walletAmountPoints -
                          totalPrice -
                          freightPoints -
                          dropshipPoints
                      )}`
                  : userData?.walletAmountPoints -
                      totalPrice -
                      freightPoints -
                      dropshipPoints >=
                    0
                  ? `${pointsConvertor(
                      brandData,
                      userData.walletAmountPoints -
                        totalPrice -
                        freightPoints -
                        dropshipPoints
                    )}`
                  : translate(
                      "_BASKET_PRICE_EXCEEDS_BALANCE_TEXT_",
                      "Du har ikke point nok til at gennemføre ordren"
                    )
              }
            />
          </div>
        )}

        <div className={classes.basketOverviewDelivery}>
          <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
            {!!brandData &&
              !!brandData.features &&
              !!brandData.features.customDeliveryAddress && (
                <ContentSmall
                  style={{
                    color: styleGuide.color16,
                    alignSelf: "flex-end",
                    cursor: "pointer",
                    marginBottom: 5,
                  }}
                  onClick={() => setUpdateDeliveryInfo(true)}
                >
                  {translate(
                      "_BASKET_OVERVIEW_UPDATE_DELIVERY_",
                      "Opdater Leveringsadresse"
                    )}
                </ContentSmall>
              )}

            <Headline6
              translationKey="_BASKET_OVERVIEW_DELIVERY_INFORMATION_CAPTION"
              fallbackText="Leveringsoplysninger"
            />
            
            {!!brandData &&
              !!brandData.features &&
              !!brandData.features.companyDetail && (
                <ContentSmall
                  fallbackText={`${userData.userCompany} - ${userData.userCompanyUID}`}
                />
              )}

            {updateDeliveryInfo ? (
              // The Update Form
              <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <input
                  type="text"
                  value={updatedName}
                  placeholder="Navn"
                  onChange={(e) => setUpdatedName(e.target.value)}
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    borderRadius: 10,
                    fontSize: "12px",
                  }}
                />
                <input
                  type="text"
                  value={updatedAddress}
                  placeholder="Addresse"
                  onChange={(e) => setUpdatedAddress(e.target.value)}
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    borderRadius: 10,
                    fontSize: "12px",
                  }}
                />
                <input
                  type="text"
                  value={updatedZip}
                  placeholder="Postnummer"
                  onChange={(e) => setUpdatedZip(e.target.value)}
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    borderRadius: 10,
                    fontSize: "12px",
                  }}
                />
                <input
                  type="text"
                  value={updatedCity}
                  placeholder="By"
                  onChange={(e) => setUpdatedCity(e.target.value)}
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    borderRadius: 10,
                    fontSize: "12px",
                  }}
                />
                <input
                  type="text"
                  value={updatedPhone}
                  placeholder="Telefonnummer"
                  onChange={(e) => setUpdatedPhone(e.target.value)}
                  style={{
                    padding: 5,
                    border: "1px solid #ccc",
                    borderRadius: 10,
                    fontSize: "12px",
                  }}
                />
                <ButtonMedium1
                  showIcon={false}
                  style={{
                    padding: 5,
                    backgroundColor: styleGuide.color16,
                    color: "white",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUserData({ 
                      ...userData, 
                      userPhone: updatedPhone
                    });
                    setUpdateDeliveryInfo(false);
                    setIsAddressCustom(true);
                  }}
                >
                  Gem oplysninger
                </ButtonMedium1>
                <ButtonMedium1
                  showIcon={false}
                  style={{
                    padding: 5,
                    backgroundColor: "grey",
                    color: "white",
                    borderRadius: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setUpdateDeliveryInfo(false)}
                >
                  Annuller
                </ButtonMedium1>
              </div>
            ) : addresses && addresses.length > 1 ? (
              selectedAddress ? (
                <span style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                  <ContentSmall fallbackText={selectedAddress} />
                  <span
                    style={{
                      fontSize: 10,
                      color: styleGuide.color16,
                      cursor: "pointer",
                      fontWeight: "550",
                    }}
                    onClick={() => setSelectedAddress(null)}
                  >
                    {translate("_BASKET_PAGE_ADDRESS_CHANGE_", "Lave om *")}
                  </span>
                </span>
              ) : (
                <CustomSelect
                  style={{
                    fontSize: styleGuide.contentSmallFontSize,
                    fontFamily: styleGuide.fontFamily,
                  }}
                  label={translate(
                    "_BASKET_PAGE_ADDRESS_SELECT_",
                    "Vælg adresse"
                  )}
                  helpObj={{ errorText: "error text" }}
                  value={selectedAddress}
                  onChange={(e) => setSelectedAddress(e.target.value)}
                  custlabelStyle={{
                    marginLeft: 0,
                    fontFamily: styleGuide.fontFamily,
                    fontSize: styleGuide.contentSmallFontSize,
                  }}
                  customMenuStyle={{
                    fontSize: styleGuide.contentSmallFontSize,
                    fontFamily: styleGuide.fontFamily,
                  }}
                  options={addresses?.map((address) => ({
                    value: `${address.Dealer_Navn} ${address.Dealer_adresse}, ${address.Dealer_zip} ${address.Dealer_by}`,
                    label: `${address.Dealer_Navn} ${address.Dealer_adresse}, ${address.Dealer_zip} ${address.Dealer_by}`,
                  }))}
                />
              )
            ) : (
              <>
              <ContentSmall
                fallbackText={`${updatedName ? updatedName : userData.userName}`}
              />
              <ContentSmall
                fallbackText={`${updatedAddress ? updatedAddress : userData.userAddress}, ${updatedZip ? updatedZip : userData.userZipcode} ${updatedCity ? updatedCity : userData.userCity}`}
              />
              </>
              
            )}
            {userData?.userPhone  && !updateDeliveryInfo && (
              <ContentSmall fallbackText={updatedPhone ? updatedPhone : userData.userPhone} />
            )}
            {userData?.userEmail && (
              <ContentSmall fallbackText={userData.userEmail} />
            )}
          </div>

          {!!brandData?.features?.disablePoints ? null : (
            <>
              {!!brandData?.features?.deliveryInformationExtra?.enable ? (
                <div
                  className={classes.basketOverviewDelivery}
                  style={{ marginTop: 0 }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <Headline6
                      translationKey="_BASKET_OVERVIEW_DELIVERY_INFORMATION_EXTRA_CAPTION_"
                      fallbackText="Leveringsinformation ekstra"
                    />
                    <ContentSmall
                      configuredLabel={
                        brandData?.features?.deliveryInformationExtra?.text?.[
                          langCode
                        ]
                      }
                      fallbackText=""
                    />
                  </div>
                </div>
              ) : null}
              <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <Headline6
                  translationKey="_BASKET_OVERVIEW_DELIVERY_TIME_CAPTION"
                  fallbackText="Leveringstid"
                />
                <ContentSmall
                  translationKey="_BASKET_OVERVIEW_DELIVERY_TIME_TEXT_"
                  fallbackText={`Lagervarer afsendes inden for 3-4 hverdage.`}
                />
              </div>
              {!!freightInfo?.enableFreightPriceInterval && (
                <FreightInfo
                  freightIntervals={freightInfo.freightIntervals}
                  showLoadMore
                />
              )}
            </>
          )}
        </div>
        {!readOnly && (
          <>
            {skipWallet || brandData?.features?.disablePoints ? null : (
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <CustomCheckbox
                  checked={readTermsAndConditions}
                  onChange={() =>
                    setreadTermsAndConditions(!readTermsAndConditions)
                  }
                />
                <ContentSmall
                  translationKey="_BASKET_OVERVIEW_I_HAVE_READ_"
                  fallbackText="Jeg har læst "
                  style={{ color: styleGuide.color16, marginLeft: -16 }}
                />
                <a
                  target="_blank"
                  href="/terms"
                  style={{ marginBottom: "5px" }}
                >
                  <ContentSmall
                    translationKey="_TERMS_AND_CONDITIONS_"
                    fallbackText="handelsbetingelserne"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  />
                </a>
                <ContentSmall
                  translationKey="_MANDATORY_MARKER_"
                  fallbackText="*"
                  style={{ color: styleGuide.color16 }}
                />
              </div>
            )}

            {missingParams.length > 0 && (
              <ContentSmall
                style={{ color: "red" }}
                fallbackText={userAddressErrorText}
              />
            )}
            {minOrderViolatedProducts.length > 0 && (
              <div>
                <ContentSmall
                  translationKey="_MINIMUM_ORDER_QUANTITY_ERROR_"
                  fallbackText="Et eller flere produkter kræver en minimumsbestilling, som ikke er opfyldt:"
                  style={{ color: "red" }}
                />
                {minOrderViolatedProducts.map((p) => (
                  <div>
                    <ContentSmall style={{ color: "red" }}>
                      {`- ${getProductTitle(p)} (${translate(
                        "_MINIMUM_X_PCS_",
                        "minimum ## stk."
                      ).replace("##", p.minCount)})`}
                    </ContentSmall>
                  </div>
                ))}
              </div>
            )}

            {isLoading ? (
              <div>Placerer ordren....</div> 
            ) : (
              <ButtonLarge1
                disabled={unavailable || minOrderViolatedProducts.length > 0}
                translationKey="_CONFIRM_ORDER_"
                fallbackText="Bekræft ordre"
                onClick={() => onSubmitButton()}
              />
            )}

            {/* <Link to='/terms' target="_blank">
                        <ContentSmall translationKey="_BASKET_OVERVIEW_DELIVERY_AND_RETURN_LABEL_" fallbackText="Levering og retur"
                            style={{ textDecoration: 'underline', marginTop: 10, cursor: 'pointer' }}
                        />
                    </Link> */}
          </>
        )}
      </div>
      <Modal
        show={showError}
        onHide={() => setShowError(false)}
        style={{ margin: "auto" }}
        centered
      >
        <Modal.Header
          className="pb-0 border-0"
          closeButton
          style={{ fontSize: 9 }}
        >
          <Headline6
            style={{ color: styleGuide.color13 }}
            translationKey="_ERROR_"
          >
            Fejl
          </Headline6>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-4">
          <p style={{ textAlign: "center" }}>{errorMessage}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const BasketItemCard = (props) => {
  const navigate = useNavigate();
  const classes = jss();
  const {
    styleGuide,
    userData,
    setBasket,
    accessibleShops,
    brandData,
    langCode,
  } = useContext(AppContext);
  const hideQuantityModifier =
    brandData?.features?.enableOnePointUsers || false;
  const {
    basket,
    showDivider,
    readOnly,
    stockError,
    minOrderError,
    setUnavailable,
    baskets,
  } = props;
  const productDetails =
    basket.product && basket.product.length ? basket.product[0] : {};
  productDetails.ShopID = !!productDetails.ShopID
    ? productDetails.ShopID
    : accessibleShops && accessibleShops.length > 0
    ? accessibleShops[0].ShopID
    : null;
  const allVariations = productDetails.variations || [];
  const selectedVariation = allVariations.filter(
    (v) => v.VariationID === basket.VariationID
  )[0];

  const price =
    (productDetails.discountPointComputed
      ? productDetails.discountPointComputed
      : productDetails.productPointComputed) * basket.amount;
  const pointSuffix = translate("_POINTS_SHORT_", "pt");
  const variationImage = () => {
    let returnImg = "";

    const allImages = productDetails.images || [];
    if (!allImages.length)
      return {
        src: "",
        backgroundColor: "",
      };

    const variationImages = selectedVariation ? selectedVariation.images : [];
    returnImg = variationImages.length
      ? variationImages[0].imageOriginSource
      : "";
    if (returnImg)
      return {
        src: returnImg,
        backgroundColor: variationImages.length
          ? variationImages[0].backgroundColor
          : "",
      };

    const featuredImage = allImages.filter((item) => !!item.featuredImage);
    returnImg = featuredImage.length ? featuredImage[0].imageOriginSource : "";
    if (returnImg)
      return {
        src: returnImg,
        backgroundColor: variationImages.length
          ? variationImages[0].backgroundColor
          : "",
      };

    const firstImage = allImages.sort((a, b) => a.poolOrder - b.poolOrder)[
      allImages.length - 1
    ];
    return {
      src: firstImage.imageOriginSource,
      backgroundColor: firstImage.backgroundColor,
    };
  };

  const image = variationImage();

  const productReaction = async (product) => {
    if (!!readOnly) return;
    const ReactionID = product.reaction ? product.reaction.ReactionID : null;
    await callAPI("/product/reaction", "POST", {
      body: {
        ReactionTargetID: product.ProductID,
        reactionData: JSON.stringify({ ShopID: product.ShopID }),
        ...(ReactionID && { ReactionID }),
      },
    });
  };

  const moveToWishlist = async () => {
    if (!!readOnly) return;
    productReaction(productDetails);
    await deleteItemFromBasket();
  };

  const deleteItemFromBasket = async () => {
    if (!!readOnly) return;
    await removeProductFromBasket(basket.BasketID);
    await setupBasketData({ userData, setBasket });
  };

  const deleteItemsFromBasket = async () => {
    !!baskets &&
      !!baskets.length &&
      baskets.forEach(async (item) => {
        if (basket.ProductPackageID === item.ProductPackageID) {
          await removeProductFromBasket(item.BasketID);
          await setupBasketData({ userData, setBasket });
        }
      });
  };

  const setQuantity = async (amount) => {
    if (amount <= 0) return;
    await updateProductQuantityInBasket(basket.BasketID, amount);
    await setupBasketData({ userData, setBasket });
  };

  const showQuantity = (basket) => {
    let productShopID = basket.ShopID;
    let shopSelectionLimit =
      accessibleShops && accessibleShops.length
        ? accessibleShops.filter(
            (basketItem) => basketItem.ShopID === productShopID
          )
        : [];
    shopSelectionLimit =
      shopSelectionLimit.length > 0
        ? shopSelectionLimit[0].shopSelectionLimit
        : 0;
    if (shopSelectionLimit === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!selectedVariation) {
      setUnavailable(true);
    }
  }, [basket]);

  const productTitle = getTranslatedProductTitle(productDetails, langCode)

  const Divider = () => (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignSelf: "stretch",
        height: 1,
        backgroundColor: styleGuide.color4,
      }}
    />
  );

  return (
    <>
      <div
        id="basket-item-card"
        style={{ backgroundColor: styleGuide.color1, overflow: "hidden" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "1rem", padding: "40px 0px" }}>
            {!!image.src && (
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  border: `0.5px solid ${styleGuide.color2}`,
                }}
                onClick={() => navigate(`/product/${productDetails.ProductID}`)}
              >
                <Image
                  className={classes.imageContainer}
                  src={`url(${image.src})`}
                  style={{
                    backgroundColor:
                      image.backgroundColor ||
                      styleGuide.productImageBackgroundColor,
                  }}
                />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {productDetails.manufacturer && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/product/${productDetails.ProductID}`)
                      }
                    >
                      <Headline6 fallbackText={productDetails.manufacturer} />
                    </div>
                  )}
                  {productTitle && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/product/${productDetails.ProductID}`)
                      }
                    >
                      <ExplainerText style={{ color: styleGuide.color5 }}>
                        {productTitle}
                      </ExplainerText>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginTop: "0.25rem",
                      alignItems: "center",
                    }}
                  >
                    {!!selectedVariation &&
                      ((selectedVariation.colorHexcode &&
                        selectedVariation.variationSize) ||
                        selectedVariation.variationTitle) && (
                        <>
                          <ExplainerText
                            style={{ color: styleGuide.color5 }}
                            fallbackText={
                              selectedVariation.variationSize
                                ? `${selectedVariation.variationSize}`
                                : `${selectedVariation.variationTitle}`
                            }
                          />
                          {selectedVariation.colorName ? (
                            <ExplainerText
                              style={{ color: styleGuide.color5 }}
                              fallbackText={`, ${selectedVariation.colorName}`}
                            />
                          ) : null}
                        </>
                      )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.75rem",
                      alignItems: "center",
                      marginTop: "0.25rem",
                    }}
                  >
                    <QuantityInput
                      quantity={basket.amount}
                      setQuantity={setQuantity}
                      readOnly={readOnly || hideQuantityModifier}
                      disabled={
                        !basket ||
                        !!basket.ProductPackageID ||
                        !showQuantity(basket)
                      }
                      showExplainer={readOnly}
                    />

                    {!!stockError &&
                      !isNaN(stockError.currentStock) &&
                      basket.amount > stockError.currentStock && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "2px",
                            alignItems: "center",
                            marginLeft: 10,
                          }}
                        >
                          <Headline6
                            style={{
                              color: styleGuide.color13,
                              fontWeight: "700",
                              lineHeight: "21px",
                            }}
                            fallbackText={`${stockError.currentStock}`}
                          />
                          &nbsp;
                          <ExplainerText
                            style={{ color: styleGuide.color13 }}
                            translationKey="_STOCK_AVAILABLE_TEXT_"
                            fallbackText="stk. på lager"
                          />
                        </div>
                      )}
                    {!!minOrderError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "2px",
                          alignItems: "center",
                          marginLeft: 10,
                        }}
                      >
                        <ExplainerText style={{ color: styleGuide.color13 }}>
                          {translate(
                            "_PRODUCT_MINIMUM_ORDER_QUANTITY_ERROR_",
                            "Dette produkt kræver en minimumsbestilling på ## stk, på tværs af størrelser."
                          ).replace("##", productDetails.minCount)}
                        </ExplainerText>
                      </div>
                    )}
                    {productDetails.productStatus === "UNAVAILABLE" ||
                      (!selectedVariation && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "2px",
                            alignItems: "center",
                            marginLeft: 20,
                          }}
                        >
                          <ExplainerText
                            style={{ color: styleGuide.color13 }}
                            translationKey="_UNAVAILABLE_TEXT_"
                            fallbackText="Ikke tilgængeligt"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {!readOnly &&
                  (!productDetails.reaction ||
                    !productDetails.reaction.ReactionID) && (
                    <div onClick={moveToWishlist} style={{ cursor: "pointer" }}>
                      <ExplainerText
                        translationKey="_BASKET_MOVE_TO_WISHLIST_LABEL_"
                        fallbackText="Flyt til ønskelisten"
                      />
                    </div>
                  )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  minWidth: 50,
                }}
              >
                {!!brandData?.features?.disablePrice ? null : (
                  <CaptionText
                    style={{ color: styleGuide.color16 }}
                    fallbackText={`${pointsConvertor(
                      brandData,
                      price,
                      pointSuffix
                    )}`}
                  />
                )}
                {!readOnly && (
                  <div
                    onClick={
                      !!basket && !!basket.ProductPackageID
                        ? deleteItemsFromBasket
                        : deleteItemFromBasket
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <ExplainerText
                      translationKey="_BASKET_REMOVE_ITEM_LABEL_"
                      fallbackText="Fjern"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!!showDivider && <Divider />}
      </div>
    </>
  );
};

export const ProductsBasedOnWalletBalance = (props) => {
  const {
    styleGuide,
    basket,
    accessibleShops,
    userData,
    setBasket,
    brandData,
  } = useContext(AppContext);
  const classes = jss(styleGuide);
  const navigate = useNavigate();
  const { button, buttonLeft, buttonRight, backgroundColor, style } = props;
  const [scrollProducts, setScrollProducts] = useState([]);
  const ShopID =
    accessibleShops && accessibleShops.length > 0
      ? accessibleShops[0].ShopID
      : null;
  const [totalPrice, settotalPrice] = useState(0);
  const [remainingPoints, setremainingPoints] = useState(0);
  const [caption, setcaption] = useState("");
  const [selectedVariation, setselectedVariation] = useState(null);
  const [selectedVariationInBasket, setselectedVariationInBasket] =
    useState(null);
  const [productDetails, setproductDetails] = useState({});
  const [productVariations, setproductVariations] = useState([]);

  const [variationSizes, setVariationSizes] = useState([]);
  const [selectedSize, setselectedSize] = useState(null);

  const [variationColors, setVariationColors] = useState([]);
  const [selectedColor, setselectedColor] = useState(null);

  const [variationTitles, setVariationTitles] = useState([]);
  const [selectedTitle, setselectedTitle] = useState(null);
  const [showVariations, setShowVariations] = useState(false);
  const pointSuffix = translate("_POINTS_SHORT_", "pt");
  const captionPrefix = translate(
    "_USE_YOUR_REMAINING_POINTS_",
    "Brug dine sidste"
  );

  const productReaction = async (product) => {
    const ReactionID = product.reaction ? product.reaction.ReactionID : null;
    await callAPI("/product/reaction", "POST", {
      body: {
        ReactionTargetID: product.ProductID,
        reactionData: JSON.stringify({ ShopID: product.ShopID }),
        ...(ReactionID && { ReactionID }),
      },
    });
    fetchProducts();
    await setupBasketData({ userData, setBasket });
  };

  const calculateTotalPrice = () => {
    let cost = 0;
    basket.forEach((item) => {
      const productDetails = item.product[0];
      if (!productDetails) return;
      const price =
        (productDetails.discountPointComputed
          ? productDetails.discountPointComputed
          : productDetails.productPointComputed) * item.amount;
      cost += price;
    });
    settotalPrice(cost);
    setremainingPoints(
      cost <= userData.walletAmountPoints
        ? `${userData.walletAmountPoints - cost}`
        : -1
    );
  };

  const updateCaption = () => {
    setcaption(
      `${captionPrefix} ${
        remainingPoints > 999
          ? (remainingPoints / 1000).toFixed(3)
          : remainingPoints
      } ${translate("_POINTS_", "point")}`
    );
  };

  const fetchProducts = async () => {
    if (!ShopID) return null;
    let allProducts = await callAPI(`/shop/${ShopID}/products`, "GET");
    let shopProducts = allProducts.filter(
      (item) =>
        (item.discountPointComputed
          ? item.discountPointComputed
          : item.productPointComputed) <= remainingPoints
    );

    if (shopProducts && !!shopProducts.length) {
      shopProducts.forEach((product) => {
        setupProductImages(product, true)
      });
      setScrollProducts(shopProducts);
      return;
    }
    setScrollProducts([]);
  };
  const resetVariationDetails = () => {
    setselectedVariation(null);
    setselectedVariationInBasket(null);
    setselectedSize(null);
    setselectedColor(null);
    setselectedTitle(null);
  };

  const fetchProductDetails = async (ProductID) => {
    if (!ProductID) return;
    let productInfo = await callAPI(`/product/${ProductID}`, "GET", {
      query: { ShopID },
    });
    if (productInfo && !!productInfo.length) {
      setproductDetails(productInfo[0]);
      assignVariations(productInfo[0]);
      return productInfo[0];
    }
    return null;
  };

  const handleTagClick = async (product) => {
    resetVariationDetails();
    const productDetails = await fetchProductDetails(product.ProductID);
    if (!productDetails.variations.length) return;
  };

  const assignVariations = async (product) => {
    if (!product || !product.variations || !product.variations.length) {
      setproductVariations([]);
      return;
    }
    product.variations.forEach((variation) => {
      variation.ImageID =
        variation.images && variation.images.length
          ? variation.images.map((img) => img.ImageID)[0]
          : null;
    });
    setproductVariations(product.variations);
    let sizes = [];
    let colors = [];
    let titles = [];
    product.variations.map((vr) => {
      const available =
        !vr.variationStockable || !!vr.stockOverride
          ? true
          : !vr.variationStock
          ? false
          : true;
      if (!sizes.filter((v) => v.variant === vr.variationSize).length)
        !!vr.variationSize &&
          sizes.push({ variant: vr.variationSize, available });
      if (!colors.filter((v) => v.variant === vr.colorHexcode).length)
        !!vr.colorHexcode &&
          colors.push({ variant: vr.colorHexcode, available });
      if (!titles.filter((v) => v.variant === vr.variationTitle).length)
        !!vr.variationTitle &&
          titles.push({ variant: vr.variationTitle, available });
    });
    setVariationSizes(sizes);
    setVariationColors(colors);
    setVariationTitles(titles);
    if (product.variations.length === 1) {
      setselectedVariation(product.variations[0]);
    }
    if (product.variations.length > 1) {
      setShowVariations(true);
    }
    return;
  };

  const isDisabled = () =>
    (variationSizes.length ? (selectedSize ? false : true) : false) ||
    (variationColors.length ? (selectedColor ? false : true) : false) ||
    (variationTitles.length && !variationSizes.length && !variationColors.length
      ? selectedTitle
        ? false
        : true
      : false);

  const variationAlreadyInBasket = async () => {
    if (!selectedVariation) return null;

    const existing = basket.filter(
      (item) =>
        item.ProductID === productDetails.ProductID &&
        item.VariationID === selectedVariation.VariationID
    );

    const basketActionResponse = !existing.length
      ? await addProductToBasket(
          ShopID,
          productDetails.ProductID,
          selectedVariation.VariationID,
          1
        )
      : await updateProductQuantityInBasket(
          existing[0].BasketID,
          existing[0].amount + 1
        );
    setShowVariations(false);
    setupBasketData({ userData, setBasket });
    return existing.length ? existing[0] : null;
  };

  // const addToCart = async () => {
  //     if (!selectedVariation) return
  //     if (isDisabled()) {
  //         ((variationSizes.length && !selectedSize) || (variationColors.length && !selectedColor))
  //             ? alert('Either "size" or "color" or both not selected')
  //             : alert('No variations selected')
  //         return
  //     }
  //     const basketActionResponse = !selectedVariationInBasket
  //         ? await addProductToBasket(ShopID, productDetails.ProductID, selectedVariation.VariationID, 1)
  //         : await updateProductQuantityInBasket(selectedVariationInBasket.BasketID, selectedVariationInBasket.amount + 1)
  //     setShowVariations(false)
  //     setupBasketData({ userData, setBasket })
  // }

  useEffect(() => {
    if (!selectedVariation) {
      setselectedVariationInBasket(null);
    }else{
      setselectedVariationInBasket(variationAlreadyInBasket());
    }
  }, [selectedVariation]);

  // useEffect(() => {
  //     addToCart()
  // }, [selectedVariationInBasket]);

  useEffect(() => {
    calculateTotalPrice();
    updateCaption();
  }, [basket]);

  useEffect(() => {
    fetchProducts();
    updateCaption();
  }, [totalPrice]);

  useEffect(() => {
    if (isDisabled()) {
      setselectedVariation(null);
    }else{

      const variationByTitle = selectedTitle
        ? productVariations.filter(
            (item) => item.variationTitle === selectedTitle
          )
        : [];
      const variationBySize = selectedSize
        ? productVariations.filter((item) =>
            selectedColor
              ? item.variationSize === selectedSize &&
                item.colorHexcode === selectedColor
              : item.variationSize === selectedSize
          )
        : [];
      const variationByColor = selectedColor
        ? productVariations.filter((item) =>
            selectedSize
              ? item.variationSize === selectedSize &&
                item.colorHexcode === selectedColor
              : item.colorHexcode === selectedColor
          )
        : [];

      if (variationBySize.length === 1) {
        setselectedVariation(variationBySize[0]);
      }else if (variationByColor.length === 1) {
        setselectedVariation(variationByColor[0]);
      }else if (variationByTitle.length) {
        setselectedVariation(variationByTitle[0]);
      }else{
        setselectedVariation(null);
      }
    }
  }, [selectedSize, selectedTitle, selectedColor]);

  return !scrollProducts.length || remainingPoints < 0 ? null : (
    <div className="horizontal-scroll-wrapper products-on-scroll" style={style}>
      <HorizontalScrollBlock
        caption={
          brandData?.features?.points === false ||
          !!brandData?.features?.hideUserPointBalance
            ? " "
            : caption
        }
        button={button || null}
        buttonLeft={buttonLeft || null}
        buttonRight={buttonRight || null}
      >
        {scrollProducts.map((item) => (
          <ProductNormal
            key={item.ProductID}
            productID={item.ProductID}
            showTag={true}
            tagOptions={{
              fallbackText: `${translate(
                "_BASKET_ADD_PRODUCT_TO_BASKET_TAG_",
                "Tilføj til bestilling"
              )}`,
              showClose: false,
              onClick: (e) => {
                e.stopPropagation();
                handleTagClick(item);
              },
            }}
            tagStyles={{ borderRadius: 8 }}
            points={`${item.discountPointComputed ? item.discountPointComputed : item.productPointComputed}`}
            liked={item.reaction && item.reaction.ReactionID ? true : false}
            className="productlarge item"
            style={{ boxShadow: `0px 0px 3px 1px rgba(34, 34, 34, 0.15)` }}
            text2={item.elementTitle}
            text1={item.manufacturer}
            wishlistCallback={() => productReaction(item)}
            imageSrc={`url(${item.image?.src})`}
            imageBackgroundColor={item.image?.backgroundColor}
            activityName="basket"
          />
        ))}
      </HorizontalScrollBlock>
      <Modal
        show={showVariations}
        onHide={() => setShowVariations(false)}
        style={{ margin: "auto" }}
        centered
      >
        <Modal.Header
          className="pb-0 border-0"
          closeButton
          style={{ fontSize: 9 }}
        >
          <Headline6 translationKey="_PRODUCT_VARIATION_TITLE_LABEL_">
            Variationer
          </Headline6>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div
            className="product-variations"
            style={{ marginTop: 20, marginBottom: 48 }}
          >
            <Grid container>
              <Grid
                size={{
                  xs: 12,
                  sm: 6
                }}>
                {!!variationSizes.length && (
                  <>
                    <CaptionText
                      style={{ color: styleGuide.color6 }}
                      translationKey="_PRODUCT_VARIATION_SIZE_LABEL_"
                      fallbackText="Størrelser"
                    />
                    <div
                      style={{
                        display: "flex",
                        flex: 0.5,
                        gap: "0.5rem",
                        marginTop: 10,
                        flexWrap: "wrap",
                      }}
                    >
                      {variationSizes.map((item, idx) => {
                        const allowSelection =
                          selectedSize === item.variant
                            ? true
                            : selectedColor
                            ? productVariations.filter(
                                (v) =>
                                  v.variationSize === item.variant &&
                                  v.colorHexcode === selectedColor
                              ).length
                              ? true
                              : false
                            : true;
                        return (
                          !!item.variant && (
                            <div
                              key={idx}
                              className="size-variation"
                              onClick={() =>
                                allowSelection
                                  ? setselectedSize(
                                      selectedSize === item.variant
                                        ? null
                                        : item.variant
                                    )
                                  : null
                              }
                              style={{
                                border:
                                  selectedSize === item.variant
                                    ? `1px solid ${styleGuide.color4}`
                                    : "1px solid transparent",
                              }}
                            >
                              <CaptionText
                                style={{
                                  color: !!allowSelection
                                    ? styleGuide.color7
                                    : styleGuide.color5,
                                }}
                              >
                                {item.variant}
                              </CaptionText>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </>
                )}
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6
                }}>
                {!!variationColors.length && (
                  <>
                    <CaptionText
                      style={{ color: styleGuide.color6 }}
                      translationKey="_PRODUCT_VARIATION_COLOR_LABEL_"
                      fallbackText="Farver"
                    />
                    <div
                      style={{
                        display: "flex",
                        flex: 0.5,
                        gap: "0.5rem",
                        marginTop: 10,
                        flexWrap: "wrap",
                      }}
                    >
                      {variationColors.map((item, idx) => {
                        const allowSelection =
                          selectedColor === item.variant
                            ? true
                            : selectedSize
                            ? productVariations
                                .map((v) => {
                                  if (
                                    !v.variationStockable || !!v.stockOverride
                                      ? true
                                      : v.variationStock && v.variationStock > 0
                                      ? true
                                      : false
                                  ) {
                                    return v;
                                  }
                                })
                                .filter((v) => {
                                  if (!v) return false;
                                  return (
                                    v.colorHexcode === item.variant &&
                                    v.variationSize === selectedSize
                                  );
                                }).length
                              ? true
                              : false
                            : true;

                        return (
                          !!item.variant && (
                            <div
                              key={idx}
                              className="color-variation"
                              onClick={() => {
                                if (!allowSelection) return null;
                                const color =
                                  selectedColor === item.variant
                                    ? null
                                    : item.variant;
                                setselectedColor(color);
                                return;
                              }}
                              style={{
                                border:
                                  selectedColor === item.variant
                                    ? `1px solid ${styleGuide.color4}`
                                    : "1px solid transparent",
                              }}
                            >
                              <div
                                style={{
                                  width: 25,
                                  height: 25,
                                  backgroundColor: `${item.variant}`,
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {allowSelection ? (
                                  <Tick />
                                ) : (
                                  <Close
                                    fill={styleGuide.color1}
                                    style={{ width: 9, height: 9 }}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </>
                )}
              </Grid>
              <Grid size={12}>
                {!!variationTitles.length &&
                  !variationColors.length &&
                  !variationSizes.length && (
                    <>
                      <CaptionText
                        style={{ color: styleGuide.color6 }}
                        translationKey="_PRODUCT_VARIATION_TITLE_LABEL_"
                        fallbackText="Variationer"
                      />
                      <div
                        style={{
                          display: "flex",
                          flex: 0.5,
                          gap: "0.5rem",
                          marginTop: 10,
                          flexWrap: "wrap",
                        }}
                      >
                        {variationTitles.map(
                          (item, idx) =>
                            !!item.variant && (
                              <div
                                key={idx}
                                className="title-variation"
                                onClick={() => {
                                  const title =
                                    selectedTitle === item.variant
                                      ? null
                                      : item.variant;
                                  setselectedTitle(title);
                                  return;
                                }}
                                style={{
                                  border:
                                    selectedTitle === item.variant
                                      ? `1px solid ${styleGuide.color4}`
                                      : "1px solid transparent",
                                }}
                              >
                                <CaptionText
                                  style={{ color: styleGuide.color7 }}
                                >
                                  {item.variant}
                                </CaptionText>
                              </div>
                            )
                        )}
                      </div>
                    </>
                  )}
              </Grid>
              {/* <Grid item xs={12}>
                                <ButtonMedium1
                                    style={{ margin: `40px auto 0px` }}
                                    onClick={addToCart}
                                    icon={<Cart1 fill={styleGuide.color1} style={{ marginLeft: '64px' }} />}
                                    fallbackText={`${productDetails.discountPointComputed ? productDetails.discountPointComputed : productDetails.productPointComputed} ${pointSuffix}`}
                                />
                            </Grid> */}
            </Grid>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
