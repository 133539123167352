import { useContext, useState, useEffect } from "react"
import ButtonLarge4 from "components/elements/ButtonLarge4"
import ContentLarge from "components/elements/ContentLarge"
import Headline2 from "components/elements/Headline2"
import ProductLarge from "components/elements/ProductLarge"
import { AppContext } from "contexts/AppContext"
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom"
import { callAPI } from "utils/API"
import { getTranslatedProductTitle, newLineToBreakTag, pointsConvertor } from "utils/general"
import { translate } from "utils/Translate"
import "./HighlightedProductBlock.css"
import ExplainerTextTiny from "components/elements/ExplainerTextTiny"
import { Image } from "react-bootstrap"
import { setupProductImages } from "utils/productImageHelper"

const jss = createUseStyles({
    HighlightedProductImage: {
        maxHeight: '1000px',
        height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `46vw` : `calc(46vw + 100px)`,  
        width: '34.2vw'
    },
    HighlightedBrandImage: {
        maxHeight: '1000px',
        height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `40vw` : `calc(40vw + 100px)`,  
        width: '34.2vw'
    },
    ProductText: {},
    "@media screen and (max-width: 1024px)": {
        HighlightedProductImage: {
            height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `110vw` : `calc(110vw + 100px)`, 
        },
        HighlightedBrandImage: {
            height: (styleGuide) =>
            !!styleGuide.productInfoAsOverlay ? `100vw` : `calc(100vw + 100px)`, 
        },
        ProductText:{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": 3,
            overflow: "hidden",
        }
    },
})

const HighlightedProductBlock = (props) => {
    const navigate = useNavigate()
    const {styleGuide, brandData, accessibleShops, langCode } = useContext(AppContext)
    const classes = jss(styleGuide);
    const [product, setProduct] = useState({})
    const [highlightedProducts,setHighlightedProducts] = useState({})
    const [showFullDescription, setShowFullDescription] = useState(false)

    const productTitle = getTranslatedProductTitle(product, langCode)

    const fetchProductDetails = async () => {
        let HighlightedProduct = await callAPI(`/highlighted-product`, 'GET')
        setHighlightedProducts(HighlightedProduct)

        if (accessibleShops && accessibleShops.length){
            if (HighlightedProduct?.ProductID && HighlightedProduct?.ShopID) {
                let productInfo = HighlightedProduct?.product || [];
                if (productInfo && !!productInfo.length) {
                    setupProductImages(productInfo[0], true)
                    setProduct(productInfo[0])
                }
            }
        }
    }

    const goToCollection = () => {
        if((!highlightedProducts.ProductID && !highlightedProducts.manufacturer) || !highlightedProducts?.categoryName) return 
        return navigate(`shop/${highlightedProducts.ShopID || product.ShopID || accessibleShops[0].ShopID}?category=${!!highlightedProducts.ProductID ? highlightedProducts.categoryName.split(' ').join('_') : "Brands"}${!highlightedProducts.ProductID ? `&subCategory=${highlightedProducts.manufacturer.split(' ').join('_')}` : ""}`)
    }

    const productReaction = async (product) => {
        const ReactionID = product.reaction ? product.reaction.ReactionID : null
        await callAPI("/product/reaction", "POST", { body: { ReactionTargetID: product.ProductID, reactionData: JSON.stringify({ ShopID: product.ShopID }), ...(ReactionID && { ReactionID }) } });
        fetchProductDetails()
        // await setupBasketData({ userData, setBasket })
    }

    useEffect(() => {
        fetchProductDetails()
    },[brandData, accessibleShops])
    
    const pointSuffix = translate("_POINTS_SHORT_", "pt")
    return !brandData || !highlightedProducts || (!highlightedProducts.ProductID && !highlightedProducts.manufacturer) ? null : <>
        <div id="HighlightedProductBlock" className={`HighlightedProductBlock ${props.className || ''}`} >
            <div>
            {product?.ProductID ? 
                <ProductLarge
                    key={product.ProductID}
                    productID={product.ProductID}
                    showTag={product.enableDiscount ? true : false}
                    tagOptions={{
                        fallbackText: `${translate('_PRODUCT_DISCOUNT_TAG_TEXT_', 'Spar')} ${ pointsConvertor(brandData,product.productPointComputed - product.discountPointComputed, pointSuffix)}`,
                        showClose: false,
                    }}
                    className={`highlightedProduct ${classes.HighlightedProductImage}`}
                    tagStyles={{ borderRadius: 8 }}
                    points={`${product.discountPointComputed ? product.discountPointComputed : product.productPointComputed}`}
                    liked={product.reaction && product.reaction.ReactionID ? true : false}
                    text2={productTitle && productTitle.length > 20 ? `${productTitle.slice(0, 20)}...` : productTitle}
                    text1={product.manufacturer}
                    wishlistCallback={() => productReaction(product)}
                    imageSrc={`url(${highlightedProducts.productImageSrc|| product.image?.src})`}
                    imageBackgroundColor={!highlightedProducts.productImageSrc ? product.image?.backgroundColor : ''}
                    activityName='home'
                /> : 
                <Image
                    className={`highlightedProduct ${classes.HighlightedBrandImage}`}
                    src={highlightedProducts.brandImageUrl}
                ></Image>}
            </div>
            <div className="highlightedProductText">
                { !!highlightedProducts?.title && 
                <div>
                    <Headline2 
                    translationKey="_HIGHLIGHTED_PRODUCT_TITLE_" 
                    fallbackText={
                        !!highlightedProducts &&
                        !!highlightedProducts.translations &&
                        !!highlightedProducts.translations.length
                        ? highlightedProducts.translations.find(
                            (translation) => translation.languageKey === langCode
                          ) ? highlightedProducts.translations.find(
                            (translation) => translation.languageKey === langCode
                          ).title
                        : highlightedProducts?.title
                    : highlightedProducts?.title || ""} />
                </div>}
                { !!highlightedProducts?.content && <div>
                    <ContentLarge 
                        className={!showFullDescription ? classes.ProductText : ""} 
                        translationKey="_HIGHLIGHTED_PRODUCT_CONTENT_" 
                        fallbackText={
                            newLineToBreakTag(
                            !!highlightedProducts &&
                            !!highlightedProducts.translations &&
                            !!highlightedProducts.translations.length
                            ? highlightedProducts.translations.find(
                                (translation) => translation.languageKey === langCode
                              ) ? highlightedProducts.translations.find(
                                (translation) => translation.languageKey === langCode
                              ).content
                            : highlightedProducts?.content
                            
                        : highlightedProducts?.content || "")} />
                    <br/>
                    {!showFullDescription 
                        ? <ExplainerTextTiny style={{fontWeight: 700}} onClick={() => setShowFullDescription(!showFullDescription)} className="mt-4 d-lg-none d-sm-inline-block" translationKey="_READ_MORE_BUTTON_TEXT_" fallbackText="Læs mere" /> 
                        : <ExplainerTextTiny style={{fontWeight: 700}} onClick={() => setShowFullDescription(!showFullDescription)} className="mt-4 d-lg-none d-sm-inline-block" translationKey="_READ_LESS_BUTTON_TEXT_" fallbackText="Læs mindre" /> 
                    }
                </div>}
                { (!!highlightedProducts?.categoryName || !!highlightedProducts?.manufacturer) && <div>
                    <ButtonLarge4
                        style={{ margin: "auto" }}
                        translationKey="_HOME_HIGHLIGHTED_PRODUCT_BLOCK_BUTTON_CAPTION_"
                        onClick={goToCollection}
                    >
                        {!!highlightedProducts &&
                            !!highlightedProducts.translations &&
                            !!highlightedProducts.translations.length
                            ? highlightedProducts.translations.find(
                                (translation) => translation.languageKey === langCode
                              ) ? highlightedProducts.ProductID ? highlightedProducts.translations.find(
                                (translation) => translation.languageKey === langCode
                              ).categoryName : highlightedProducts.manufacturer
                            : highlightedProducts.ProductID ? highlightedProducts?.categoryName : highlightedProducts.manufacturer
                        : highlightedProducts.ProductID ? highlightedProducts?.categoryName : highlightedProducts.manufacturer || 'Se produkter'}
                    </ButtonLarge4>
                    </div>
                }
            </div>
        </div>
    </>
}

export default HighlightedProductBlock


